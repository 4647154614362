// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8dec5015-78cc-4caa-9cc8-58bee8e85d76",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Ic7UEgP8D",
    "aws_user_pools_web_client_id": "59a6u8ntb399tadjnkbnrsj7pd",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://2lfg43jtivactfb37rziyzwtty.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "roots-uat",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://roots-uat.s3-website-eu-west-1.amazonaws.com",
    "aws_user_files_s3_bucket": "rootsee3f62432e544888ba163df8d2e33f42-uat",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
